import * as React from "react"
// import Slider from "react-slick";
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
// import Img from "gatsby-image"
import Title from "../components/title"

const Thanks = ({ data }) => {
    const pageData = {
        titleClass: "thanks",
        title: "THANKS",
        discription: "",
      }
      const seodata = {
          title: "お問合せ誠にありがとうございます。 アヴィエラン株式会社　物流機器事業部",
          seo: {
            description: "物流の現場で使用されるスリングベルト・ラッシングベルトなどの資材の製造、販売をしております。",
          },
          slug: "contact",
      }

  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
        <div className="contact_thanks">
            <p>この度はお問合せいただき誠にありがとうございます。</p>
            
            <p><Link to="/">トップページへ</Link></p>
        </div>
    </Layout>
  )
}

export default Thanks

export const query = graphql`

  query   {
    allDatoCmsNews{
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`
